import React from "react";
import { BlogIndexFeatured } from "../blog/blogIndexFeatured";
import { BlogIndexHero } from "../blog/blogIndexHero";
import { BlogIndexPosts } from "../blog/blogIndexPosts";

import { Seo } from "../components/seo";

const BlogList = ({ pageContext, location }) => {
  const {
    blogCategories,
    currentPage,
    postsPerPage,
    page: { meta, content },
  } = pageContext;

  return (
    <>
      <Seo metaInfo={meta} location={location} />

      {!location.pathname.includes("/tag") && (
        <>
          {content.heroPost && <BlogIndexHero data={content} />}
          <BlogIndexFeatured data={content} />
        </>
      )}

      <BlogIndexPosts
        data={content}
        currentPage={currentPage}
        blogCategories={blogCategories}
        postsPerPage={postsPerPage}
        location={location}
      />
    </>
  );
};

export default BlogList;
