import React from "react";
import { PostCard } from "../components/postCard";

export const BlogIndexFeatured = ({ data }) => (
  <section className="bg-white section">
    <div data-aos="fade-up">
      <div className="container">
        <h2 className="small-title bordered mb-5 md:mb-10">
          {data.featuredTitle}
        </h2>
        {data.featuredCopy && (
          <h3 className="mb-11 lg:mb-20 text-2xl lg:text-5xl font-semibold max-w-4xl">
            {data.featuredCopy}
          </h3>
        )}

        <div className="md:grid grid-cols-2 gap-5">
          {data.featuredPosts.map((post) => (
            <PostCard
              key={post.id}
              className="mb-6 h-full bg-ghost"
              post={post}
              layout="portrait"
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);
