import React, { useEffect, useRef, useState } from "react";
import { PostCard } from "../components/postCard";
import cx from "classnames";
import sanityClient from "../lib/sanityClient";
import * as styles from "./blogIndexPosts.module.scss";
import queryString from "query-string";

export const BlogIndexPosts = ({
  data,
  blogCategories,
  postsPerPage,
  location,
}) => {
  const [posts, setPosts] = useState(null);
  let [currentPage, setCurrentPage] = useState(null);
  let [numPages, setNumPages] = useState(null);
  let [loading, setLoading] = useState(false);
  let [currentCategory, setCurrentCategory] = useState(
    queryString.parse(location.search)["category"]
  );
  const resultsRef = useRef(null);

  const fetchNumPages = async () => {
    const numPosts = await sanityClient.fetch(
      `count(*[_type == 'post' ${
        currentCategory ? `&& '${currentCategory}' in categories[]->_id` : ""
      }])`
    );
    numPages = Math.ceil(numPosts / postsPerPage);
    setNumPages(numPages);
    currentPage = 0;
    setCurrentPage(currentPage);
    fetchPosts();
  };

  const scroll = () => {
    const headerOffset = 116;
    const elementPosition = resultsRef.current.offsetTop;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const fetchPosts = async () => {
    setLoading(true);

    const start = postsPerPage * currentPage;
    const end = postsPerPage * (currentPage + 1) - 1;

    setPosts(
      await sanityClient.fetch(`
        *[_type == 'post' ${
          currentCategory ? `&& '${currentCategory}' in categories[]->_id` : ""
        }] | order(_createdAt desc)[${start}..${end}]  {
          ...,
          authors[]->{
            name
          },
          categories[]->{
            _id
          }
        }`)
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchNumPages();

    if (currentCategory) {
      setTimeout(() => scroll(), 500);
    }
  }, []);

  const changePage = (newPage) => {
    currentPage = newPage;
    setCurrentPage(newPage);
    scroll();
    fetchPosts();
  };

  const changeCategory = async (newCategory) => {
    currentCategory = newCategory;
    setCurrentCategory(newCategory);
    scroll();
    await fetchNumPages();
  };

  const hasNextPage = numPages && currentPage < numPages - 1;
  const hasPrevPage = numPages && currentPage > 0;

  return (
    <section className="bg-white section">
      <div data-aos={location.pathname.includes("/tag") && "fade-up"}>
        <div className="container">
          <h2 className="small-title bordered mb-5 md:mb-10">
            {data.postsTitle}
          </h2>

          <div
            ref={resultsRef}
            className="mb-11 md:mb-16 lg:flex flex-row items-center"
          >
            <div className="lg:ml-16 lg:mr-8 text-2xs font-semibold mb-4 mt-9 lg:mt-0 lg:mb-0">
              FILTER BY CATEGORY
            </div>
            <div className="hidden lg:block border border-dark rounded-full py-4 overflow-y-auto whitespace-nowrap">
              <button
                onClick={() => changeCategory(null)}
                className={cx(
                  !currentCategory ? "underline text-dark" : "text-gray",
                  "inline-block mx-4 last:mr-8 first:ml-8 font-serif text-sm cursor-pointer hover:text-dark"
                )}
              >
                Everything
              </button>
              {blogCategories.map((category) => (
                <button
                  onClick={() => changeCategory(category._id)}
                  className={cx(
                    currentCategory === category._id
                      ? "underline text-dark"
                      : "text-gray",
                    "inline-block mx-4 last:mr-8 first:ml-8 font-serif text-sm cursor-pointer hover:text-dark"
                  )}
                  key={category._id}
                >
                  {category.title}
                </button>
              ))}
            </div>
            <div className="input--select w-full lg:hidden">
              <select
                onChange={(event) =>
                  changeCategory(
                    event.target.value === "Everything"
                      ? null
                      : event.target.value
                  )
                }
              >
                <option value={null}>Everything</option>
                {blogCategories.map((category) => (
                  <option value={category._id} key={category._id}>
                    {category.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            className={cx(
              loading && styles.loading,
              "transition-opacity opacity-100 duration-150 ease-in-out"
            )}
          >
            {posts
              ? posts.map((post) => (
                  <PostCard
                    key={post._id}
                    className="mb-6 md:mb-9 bg-ghost"
                    post={post}
                    layout="landscape"
                  />
                ))
              : loading
              ? "Loading..."
              : "No results"}
          </div>

          {posts?.length > 0 ? (
            <div className="text-center flex flex-row max-w-2xl mx-auto justify-center">
              {hasPrevPage && (
                <div className={cx(hasNextPage && "mr-4", "w-1/2")}>
                  <button
                    onClick={() => changePage(--currentPage)}
                    className="button--outline w-full"
                    type="button"
                  >
                    Previous
                    <span className="hidden md:inline">&nbsp;Page</span>
                  </button>
                </div>
              )}
              {hasNextPage && (
                <div className={cx(hasPrevPage && "ml-4", "w-1/2")}>
                  <button
                    onClick={() => changePage(++currentPage)}
                    className="button--outline w-full"
                    type="button"
                  >
                    Next
                    <span className="hidden md:inline">&nbsp;Page</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            posts ? "No results" : ""
          )}
        </div>
      </div>
    </section>
  );
};
