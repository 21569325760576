import React from "react";
import cx from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "../components/portableText";
import { Link } from "gatsby";
import * as styles from "./blogIndexHero.module.scss";
import YoutubeEmbed from "../components/youtubeEmbed";

export const BlogIndexHero = ({ data: { heroPost, slug } }) => (
  <section className="bg-ghost mt-mnav md:mt-dnav">
    <Link
      to={`/${slug.current}/posts/${heroPost.slug.current}`}
      className="block container pb-10 md:py-20 lg:grid grid-cols-12 gap-7 items-end"
    >
      {heroPost.mainVideo?.youtubeUrl ? (
        <div className="col-span-7 flush-component">
          <YoutubeEmbed youtubeVideo={heroPost.mainVideo} />
        </div>
      ) : (
        heroPost.mainImage && (
          <div className="col-span-7 flush-component">
            <SanityImage
              alt={heroPost.title}
              {...heroPost.mainImage}
              width={858}
              height={482}
            />
          </div>
        )
      )}
      <div className={cx(styles.postContent, "col-span-5 pt-7")}>
        <div
          className={cx(styles.postTitle, "text-xl font-semibold leading-snug")}
        >
          {heroPost.title}
        </div>
        <PortableText
          className="font-serif md:my-5 my-8 text-sm md:text-base leading-snug"
          blocks={heroPost.excerpt}
        />
        {heroPost.author && (
          <div className="text-4xs md:text-3xs uppercase">
            BY {heroPost.author && heroPost.author.name}
          </div>
        )}
      </div>
    </Link>
  </section>
);
